import React from "react";
import { X } from "lucide-react";
import { LayoutGrid } from "../components/ui/layout-grid";

import Betterself from "../assets/projects/betterself.png";
import Pitcher from "../assets/projects/pitcher.png";
import Packtrax from "../assets/projects/packtrax.png";
import QuantCWRU from "../assets/projects/quantcwru.png";
import Friendivia from "../assets/projects/friendivia.png";

type ButtonInfo = {
  text: string;
  url: string;
};

type Card = {
  id: number;
  content: JSX.Element | React.ReactNode | string;
  className: string;
  thumbnail: string;
  buttons?: ButtonInfo[];
};

export const Projects = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="min-h-screen text-white overflow-hidden">
      <header className="px-4 py-4">
        <div className="flex items-center justify-between relative">
          <h2 className="text-lg font-medium">Tyler Bordeaux</h2>
          <h1 className="text-lg font-bold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Projects
          </h1>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-300 transition-colors"
          >
            <X size={24} />
          </button>
        </div>
        <div className="h-px bg-[#292828] mt-4"></div>
      </header>
      <div className="h-[calc(99vh-73px)] w-full overflow-y-auto scrollbar-hide">
        <div className="md:hidden">
          <MobileProjectView cards={cards} />
        </div>
        <div className="hidden md:block w-full h-full">
          <LayoutGrid cards={cards} />
        </div>
      </div>
    </div>
  );
};

const MobileProjectView = ({ cards }: { cards: Card[] }) => {
  return (
    <div className="flex flex-col space-y-8 p-4">
      {cards.map((card) => (
        <div key={card.id} className="bg-gray-800 rounded-lg overflow-hidden">
          <img
            src={card.thumbnail}
            alt={`Thumbnail for ${card.id}`}
            className="w-full h-48 object-cover"
          />
          <div className="p-4">
            <div className="mb-4">{card.content}</div>
            {card.buttons && card.buttons.length > 0 && (
              <div className="space-y-2">
                {card.buttons.map((button, index) => (
                  <a
                    key={index}
                    href={button.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full py-2 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 text-white text-center border border-white border-opacity-20 rounded-full transition-all duration-200 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                  >
                    {button.text}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const SkeletonOne = () => {
  return (
    <div>
      <p className="font-bold md:text-4xl text-xl text-white">
        Betterself: Daily Philosophy
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        During the pandemic, I used philosophy to help me navigate the uncertainty and anxiety, but when my schedule returned to normal (busy!) I found I did not have enough time to read philosophical texts. I created Betterself to make philosophy more accessible and easily habitual through concise daily lessons and journaling prompts based on philosophical texts.
      </p>
    </div>
  );
};

const SkeletonTwo = () => {
  return (
    <div>
      <p className="font-bold md:text-4xl text-xl text-white">
        Pitcher VC
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        As an entrepreneurial app developer, I personally experienced the difficulties to get the attention of venture capitalists without FAANG experience or an Ivy degree. I created Pitcher VC to make venture capital more accessible to underrepresented founders by allowing anyone to post shortform video pitches. Verified venture capitalists swipe through these pitches and can connect with founders on LinkedIn.
      </p>
    </div>
  );
};

const SkeletonThree = () => {
  return (
    <div>
      <p className="font-bold md:text-4xl text-xl text-white">
        PackTrax
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        With a school schedule that is different everyday of the week, my teammates and I often found ourselves underpacking or overpacking our backpacks. PackTrax is an RFID tracking system that uses a scanner, network of stickers, and a companion mobile app to ensure you never forget an item and adapts to your schedule.
      </p>
    </div>
  );
};

const SkeletonFour = () => {
  return (
    <div>
      <p className="font-bold md:text-4xl text-xl text-white">
        Pythia
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        After talking to Professor Shuai Xu at Case, I was invited to join quant[at]CWRU, working with Case students to integrate current paradigms in machine learning to solve complex financial tasks. Pythia, my first project with the team, is an open-source platform which simplifies the process of building, testing, and deploying deep learning models to trade equities.
      </p>
    </div>
  );
};

const SkeletonFive = () => {
  return (
    <div>
      <p className="font-bold md:text-4xl text-xl text-white">
        Friendivia
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        After competing in and greatly enjoying Hyland Software's hackathon, I vied to spend the summer there. Interning at Hyland, my team's job was to build an online multiplayer icebreaker game to be used for outreach and internal team bonding. I had a blast at Hyland and learned a ton about the agile development process and job roles in enterprise software businesses.
      </p>
    </div>
  );
};

export const cards = [
  {
    id: 1,
    content: <SkeletonOne />,
    className: "md:row-span-2",
    thumbnail: Betterself,
    buttons: [
      { text: "Download Betterself", url: "https://apps.apple.com/us/app/betterself-daily-philosophy/id6464329586" },
    ],
  },
  {
    id: 2,
    content: <SkeletonTwo />,
    className: "col-span-1",
    thumbnail: Pitcher,
    buttons: [
      { text: "Devpost", url: "https://devpost.com/software/pitcher-vc" },
    ],
  },
  {
    id: 3,
    content: <SkeletonThree />,
    className: "col-span-1",
    thumbnail: Packtrax,
    buttons: [
      { text: "Pitch Video", url: "https://drive.google.com/file/d/1qK9VL15ylmjzNsgvPmRUM0hDFzd7gDJj/view" },
      { text: "Pitch Presentation", url: "https://docs.google.com/presentation/d/1NilfyG2jvLIgwCBN2PjfXsElFL08ZNrb7j3L34sjo_g" },
    ],
  },
  {
    id: 4,
    content: <SkeletonFour />,
    className: "md:col-span-1",
    thumbnail: QuantCWRU,
    buttons: [
      { text: "Github", url: "https://github.com/quant-cwru/pythia" },
    ],
  },
  {
    id: 5,
    content: <SkeletonFive />,
    className: "md:col-span-1",
    thumbnail: Friendivia,
    buttons: [
      { text: "Github", url: "https://github.com/hto-projects/friendivia" },
      { text: "Play", url: "https://friendivia.com/" },
    ],
  },
];