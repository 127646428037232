"use client";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import { useRef, useEffect, useMemo } from "react";
import { cn } from "../../lib/utils";

// Fisher-Yates shuffle algorithm
const shuffleArray = (array: any[]) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export const MobileImageScroll = ({
  images,
  className,
}: {
  images: string[];
  className?: string;
}) => {
  // Randomize images order once and memoize it
  const randomizedImages = useMemo(() => shuffleArray(images), [images]);
  
  // Create a single ref for the container
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "-100px" });

  return (
    <div ref={containerRef} className={cn("w-full px-4 py-8", className)}>
      <div className="flex flex-col gap-6">
        {randomizedImages.map((image, idx) => (
          <motion.div
            key={idx}
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: idx * 0.1 }}
            className="w-full aspect-square"
          >
            <img
              src={image}
              alt="thumbnail"
              className="w-full h-full object-cover object-center rounded-lg"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}; 