"use client";
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CanvasRevealEffect } from "../components/ui/canvas-reveal-effect";
import { BackgroundGradient } from "../components/ui/background-gradient";
import { Linkedin, Folder, Telescope, User, ChevronDown } from "lucide-react";
import { Card } from "../Card";
import { About } from "./About";
import { Projects } from "./Projects";
import { Future } from "./Future";
import Me from "../assets/me.jpg";

export function Home() {
  const [showCanvas, setShowCanvas] = useState(false);
  const [currentPage, setCurrentPage] = useState("main");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      if (scrollPosition > windowHeight * 0.2) {
        setShowCanvas(true);
      } else {
        setShowCanvas(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor =
      currentPage === "main" ? "black" : "#111111";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [currentPage]);

  return (
    <div
      className={`dark min-h-screen text-white ${
        currentPage !== "main" ? "bg-[#111111]" : "bg-black"
      }`}
    >
      {" "}
      <AnimatePresence mode="wait">
        {currentPage === "main" ? (
          <motion.div
            key="main"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-black min-h-screen relative"
          >
            <div className="h-screen w-full bg-grid-white/[.1] relative flex items-center justify-center">
              <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
              <div className="relative z-10 text-center">
                <div className="w-40 h-40 mx-auto mb-6">
                  <BackgroundGradient
                    className="rounded-full h-full"
                    containerClassName="rounded-full h-full"
                  >
                    <div className="w-full h-full rounded-full overflow-hidden">
                      <img
                        src={Me}
                        alt="Tyler Bordeaux"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </BackgroundGradient>
                </div>
                <h1 className="text-4xl font-bold mb-4 text-white">
                  Tyler Bordeaux
                </h1>
                <p className="text-xl text-gray-300 w-[15ch] sm:w-[20ch] md:w-[25ch] leading-tight mx-auto">
                  Passionate about building software that brings people together
                </p>
              </div>
            </div>
            <motion.div 
              className="fixed bottom-20 inset-x-0 mx-auto w-fit z-50"
              initial={{ opacity: 1 }}
              animate={{ 
                y: [0, 10, 0],
                opacity: showCanvas ? 0 : 1
              }}
              transition={{ 
                y: {
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                },
                opacity: {
                  duration: 0.3
                }
              }}
            >
              <ChevronDown className="text-white/50" size={34} />
            </motion.div>
            <main
              className={`transition-opacity duration-500 ${
                showCanvas ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="py-20 flex flex-col lg:flex-row items-center justify-center w-full gap-4 mx-auto px-8">
                <Card
                  title="Projects"
                  icon={<Folder size={40} />}
                  onClick={() => setCurrentPage("projects")}
                >
                  <CanvasRevealEffect
                    animationSpeed={4}
                    containerClassName="bg-blue-900"
                    colors={[
                      [30, 144, 255],
                      [65, 105, 225],
                    ]}
                  />
                </Card>
                <Card title="Future" icon={<Telescope size={40} />}                   onClick={() => setCurrentPage("future")}
                >
                  <CanvasRevealEffect
                    animationSpeed={2}
                    containerClassName="bg-purple-900"
                    colors={[
                      [138, 43, 226],
                      [75, 0, 130],
                      [106, 90, 205],
                    ]}
                    dotSize={3}
                  />
                  <div className="absolute inset-0 [mask-image:radial-gradient(400px_at_center,white,transparent)] bg-black/50" />
                </Card>
                <Card
                  title="About"
                  icon={<User size={40} />}
                  onClick={() => setCurrentPage("about")}
                >
                  <CanvasRevealEffect
                    animationSpeed={3}
                    containerClassName="bg-teal-900"
                    colors={[
                      [0, 128, 128],
                      [32, 178, 170],
                      [0, 139, 139],
                    ]}
                    dotSize={2}
                  />
                </Card>
              </div>
            </main>
            <footer className="py-10 flex justify-center items-center">
              <a
                href="https://www.linkedin.com/in/tyler-bordeaux/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 transition-colors duration-300"
              >
                <Linkedin size={32} />
              </a>
            </footer>
          </motion.div>
        ) : currentPage === "about" ? (
          <motion.div
            key="about"
            initial={{ opacity: 0, backgroundColor: "black" }}
            animate={{ opacity: 1, backgroundColor: "#111111" }}
            exit={{ opacity: 0, backgroundColor: "black" }}
            transition={{ duration: 0.5 }}
            className="min-h-screen"
          >
            <About onClose={() => setCurrentPage("main")} />
          </motion.div>
        ) : currentPage === "projects" ? (
          <motion.div
            key="projects"
            initial={{ opacity: 0, backgroundColor: "black" }}
            animate={{ opacity: 1, backgroundColor: "#111111" }}
            exit={{ opacity: 0, backgroundColor: "black" }}
            transition={{ duration: 0.5 }}
            className="min-h-screen"
          >
            <Projects onClose={() => setCurrentPage("main")} />
          </motion.div>
        ) : (
          <motion.div
            key="future"
            initial={{ opacity: 0, backgroundColor: "black" }}
            animate={{ opacity: 1, backgroundColor: "#111111" }}
            exit={{ opacity: 0, backgroundColor: "black" }}
            transition={{ duration: 0.5 }}
            className="min-h-screen"
          >
            <Future onClose={() => setCurrentPage("main")} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
